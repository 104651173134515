import React from "react"
import SEO from "../../components/seo"
import HeroLandingPage from "../../components/pages/shared/hero/landing"
import FeaturesComponent from "../../components/pages/main/landing-features"
import PricingComponent from "../../components/pages/main/detail-pricing"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import EmailChecklist from "../../components/pages/main/email-checklist"
import Banner from "../../components/pages/main/banner"
import Cards from "../../components/pages/main/cards"
import MainPageInfo from "../../components/pages/main/info"
import Discount from "../../components/pages/main/discount"
import MusicAccordionComponent from "../../components/pages/crm-dlya-muzykalnoj-shkoly/accordion"

const MusicSchoolPage = (props) => {
  return (
    <Layout headerColor={"#6D20D2"} page={"landing_musicschool"}>
            <SEO title="CRM для музыкальной школы"
                 description="CRM-система для музыкальной школы"
                 url={"https://paraplancrm.ru" + props.location.pathname}
                 ogImage={"https://paraplancrm.ru/images/seo/education.png"}/>
            <HeroLandingPage
              title='CRM-система учета'
              subtitle="для музыкальных школ"
              idUnderBlock="#features"
              offsetHeader={80}
              list={[
                      '<b>Автоматизация</b> бизнес-процессов.',
                      '<b>Нет доплат</b> за количество групп.',                            
                      '<b>Удобное</b> расписание и <b>интеграция</b> с мессенджерами.',
              ]} />
            <FeaturesComponent title='В системе есть все, что вам нужно' subtitle="А не только инструменты для работы с заявками и контроля отдела продаж" />
            <Discount />
            <MainPageInfo
              title='Работа с CRM - это переход на новый уровень'
              subtitle='Параплан CRM создан специально для ведения учета клиентов и продаж в музыкальных школах.' />
            <EmailChecklist />
            <Cards />
            <Banner />
            <PricingComponent title='Тарифы' mainPage={true} mobileMenu={false} />
            <ContactFormComponent />
            <MusicAccordionComponent />
    </Layout>
  )
} 
export default MusicSchoolPage
