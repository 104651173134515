import React, { useState } from "react"
import styles from "./accordion.module.scss"
import AnimateHeight from "react-animate-height"

const data = [
  {
    name: "about",
    title: "Что такое CRM для музыкальной школы?",
    content: `CRM для музыкальной школы — это система, которая учитывает учеников, их посещения и абонементы. Она помогает управлять учебным процессом и содержит только необходимые инструменты: расписание занятий, профили учеников и преподавателей, автоматический подсчет зарплаты педагогов, отчеты, онлайн-запись учеников и автоматические уведомления.`,
  },
  {
    name: "use",
    title: "Как внедрить CRM в работу музыкальной школы?",
    content: `После выбора CRM-системы необходимо обучить сотрудников работе с ней. Иногда система хорошо подходит для учёта, но если администрация и преподаватели не понимают принципы её работы, внедрение может замедлиться. Мы рекомендуем использовать подробный чек-лист для заполнения системы, видео- и текстовые инструкции из базы знаний, а также пройти бесплатное онлайн-обучение работе с системой. Во время презентации менеджер Параплан CRM ответит на все вопросы, связанные с проблемами внедрения CRM в музыкальной школе.`,
  },
  {
    name: "features",
    title: "Как CRM помогает увеличить продажи музыкальной школе?",
    content: `CRM повышает продажи музыкальной школы. Клиенты работают в едином разделе системы, информация не хранится на личных устройствах. Каждый клиент проходит через воронку продаж, за него отвечает конкретный сотрудник. Руководитель контролирует общение, анализирует переписку и определяет слабые стороны продаж. В CRM есть готовые отчёты по воронке и менеджерам для оценки эффективности привлечения учеников. Автоматические уведомления напоминают о пробных уроках. Интеграция с мессенджерами позволяет менеджерам быстро отвечать на вопросы клиентов.`,
  },
]
const descr = "<p>Нужна ли CRM музыкальной школе? Мы в Параплан CRM уверены, что да! Контролировать " +
  "бизнес-процессы в режиме онлайн и автоматизировать рутинные задачи крайне важно. И мы расскажем, как быстро " +
  "подобрать CRM для музыкальной школы. </p><p>Обратите внимание на специализированные системы для дополнительного " +
  "образования. Они предлагают такие функции, как абонементная система, зарплатные ставки педагогов, статусы посещений " +
  "учеников, Личный кабинет ученика и преподавателя. Благодаря им рабочий процесс становится более эффективным и " +
  "организованным. Вам больше не придётся работать с множеством разрозненных документов и таблиц. Единая система учета клиентов " +
  "позволит вам удовлетворить все потребности вашего бизнеса.  </p><p>Перед покупкой тщательно протестируйте CRM в рамках бесплатного пробного периода. " +
  "CRM — это не просто программа для учёта клиентов. " +
  "Изучите особенности оформления абонементов, правила списания занятий, виды и удобство расписания, настройки автоматических действий, " +
  "доступ для сотрудников, интеграции, отчетность и аналитику. уководителям музыкальных школ следует " +
  "передать эти задачи CRM, а не выполнять вручную. </p><p>Сравните цены на подписку в различных CRM для музыкальных школ. " +
  "Определите, есть ли ограничения по числу пользователей, количеству клиентов или группам учащихся." +
  "Держите подсказку: чем дольше период подписки на систему, тем выгоднее ее стоимость. </p>"

const MusicAccordionComponent = () => {
  const [tab, setTab] = useState(null)
  return <div className={`container ${styles.tabs}`}>
    <div className={styles.descr}>
      <h2 className={styles.heading}>Советы по внедрению CRM-системы в музыкальной школе</h2>
      <div className={styles.content} dangerouslySetInnerHTML={{__html: descr }}></div>
    </div>
    <h2 className={styles.heading}>FAQs</h2>
    {data.map(t =>
      <div key={t.name}
           className={`${styles.tab} ${t.name === (tab && tab.name) ? styles.open : ""}`}
           onClick={() => setTab(t !== tab ? t : null)}>
        <div className={styles.info}>
          <div className={styles.title}>{t.title}</div>
          <AnimateHeight duration={300} height={t.name === (tab && tab.name) ? "auto" : 0}>
            <div className={styles.content}>{t.content}</div>
          </AnimateHeight>
        </div>
        <div className={styles.chevron}>
          <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.05287 11.2318L0.435026 2.66346C0.367169 1.27492 1.01181 0.631447 2.33502 0.733047L9.90108 8.28535L17.4671 0.733047C18.7903 0.631447 19.435 1.27492 19.3671 2.66346L10.7832 11.2318C10.1386 11.8414 9.66358 11.8414 9.05287 11.2318Z"
              fill="currentColor"/>
          </svg>
        </div>
      </div>,
    )}
  </div>
}

export default MusicAccordionComponent
